<template>

  <section id="video-category-list">
    <ul 
      v-if="categories_list.length > 0"
      class="list-group">
      <li 
        class="list-group-item d-flex justify-content-between align-items-center"
        v-for="(cat, k) in categories_list"
        :key="k"
      >
        <span 
          v-if="edit_category !== cat.id_categoria"
          class="text-secondary"
        >
          {{ cat.nombre_categoria }}
        </span>

        <span 
          v-if="edit_category == cat.id_categoria"
          class="col-6"
        >
          <input 
            type="text" 
            v-model="edit_category_name" 
            maxlength="30"
            class="form-control input-custom"
          />
        </span>

        <div 
          v-if="edit_category !== cat.id_categoria"
          class="dropdown dropdown-custom d-flex justify-content-center">
          <button 
            class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
            type="button" 
            data-bs-toggle="dropdown">
            <font-awesome-icon icon="ellipsis-vertical"/>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a 
                @click="activeEditCat(cat)"
                class="dropdown-item" 
                href="javascript:">
                <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                Editar
              </a>
            </li>
            <li>
              <a 
                @click="openQuestionDelete(cat)"
                class="dropdown-item" 
                href="javascript:">
                <font-awesome-icon class="pe-2 color-main" icon="trash-alt"/>
                Eliminar
              </a>
            </li>
          </ul>
        </div>

        <div v-if="edit_category == cat.id_categoria">
          <a
            href="javascript:"
            class="fs-5 me-2 btn btn-custom-color-white border"
            @click="edit_category = null"
          >
            <font-awesome-icon icon="circle-xmark" class="color-main"/>
          </a>
          <a
            href="javascript:"
            class="fs-5 btn btn-custom-color-white border"
            @click="editCategorie"
          >
          <font-awesome-icon icon="circle-check" class="color-main"/>
        </a>
        </div>

      </li>
    </ul>

    <div class="video-category__no-data" v-else>
      <img
        class="video-category__img"
        src="@/assets/img/utilities/question_face.svg"
        alt=""
      />
      <p class="video-category__text">
        Aún no se ha registrado ninguna categoría
      </p>
    </div>

    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="deleteCategorie"
    />
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />

<!--     <section class="video-category__table">
      <div
        class="video-category__table-container"
        v-if="categories_list.length > 0"
      >
        <ul class="video-category__list-container">
          <li
            v-for="(cat, k) in categories_list"
            :key="k"
            class="video-category__item-list"
          >
            <div class="video-category__name">
              <p class="my-0 ms-3" v-if="edit_category !== cat.id_categoria">
                {{ cat.nombre_categoria }}
              </p>
              <div
                class="video-category__input-container2 ms-3"
                v-if="edit_category == cat.id_categoria"
              >
                <input type="text" v-model="edit_category_name" maxlength="30" />
              </div>
            </div>

            <div
              class="video-category__icons me-3"
              v-if="edit_category !== cat.id_categoria"
            >
              <div
                class="video-category__edit"
                content="Editar categoría"
                v-tippy="{ arrow: true }"
                @click="activeEditCat(cat)"
              >
                <font-awesome-icon class="video-category__icon-pen" icon="pen" />
              </div>
              <div
                class="video-category__delete ms-2"
                content="Eliminar categoría"
                v-tippy="{ arrow: true }"
              >
                <font-awesome-icon
                  class="video-category__icon-trash"
                  icon="trash-alt"
                  @click="openQuestionDelete(cat)"
                />
              </div>
            </div>

            <div
              class="video-category__edit-text"
              v-if="edit_category == cat.id_categoria"
            >
              <P
                class="video-category__edit-cancel my-0"
                @click="edit_category = null"
                >Cancelar</P
              >
              <P
                class="video-category__edit-save my-0 ms-2"
                @click="editCategorie"
                >Guardar</P
              >
            </div>
          </li>
        </ul>
      </div>
      <div class="video-category__no-data" v-else>
        <img
          class="video-category__img"
          src="@/assets/img/utilities/question_face.svg"
          alt=""
        />
        <p class="video-category__text">
          Aún no se ha registrado ninguna categoría
        </p>
      </div>
      <Question
        v-if="open_question_modal"
        :msg="question_modal_msg"
        :hideCancel="false"
        @cancel="cancelQuestion"
        @accept="deleteCategorie"
      />
      <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="modal_status"
        @close="closeStatus"
      />
    </section> -->

  </section>

</template>

<script>
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";
import { VIDEO_CAT_EDIT, VIDEO_CAT_DELETE } from "@/apollo/mutations";
import { mapActions } from "vuex";
export default {
  components: { Question, Status },
  props: {
    categories_list: {
      type: Array,
    },
  },
  data() {
    return {
      open_question_modal: false,
      question_modal_msg: "",
      cat_to_delete: null,

      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,

      edit_category: null,
      edit_category_name: "",
      id_usuario: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
    };
  },
  methods: {
    ...mapActions("videoCatModule", [
      "addVideoCatAction",
      "addVideoCatAllAction",
    ]),
    activeEditCat(cat) {
      this.edit_category = cat.id_categoria;
      this.edit_category_name = cat.nombre_categoria;
    },
    openQuestionDelete(doc) {
      this.cat_to_delete = doc;
      this.open_question_modal = true;
      this.question_modal_msg = "¿Estas seguro de eliminar esta categoría?";
    },
    cancelQuestion() {
      this.open_question_modal = false;
    },
    closeStatus() {
      this.open_modal_status = false;
    },
    async editCategorie() {
      this.$emit("spinerClick", true);
      let query = {
        idEmpresa: this.id_empresa,
        idUsuario: this.id_usuario,
        nombre: this.edit_category_name,
        idCategoria: this.edit_category,
      };
      await this.$apollo
        .mutate({
          mutation: VIDEO_CAT_EDIT,
          variables: query,
        })
        .then((res) => {
          let new_cat = res.data.updateCategoriaVideo;
          this.addVideoCatAction({ action: "edit", data: new_cat });
          this.$emit("spinerClick", false);
          this.edit_category = null;
          this.$toast.open({
            message: "Categoría editada con éxito.",
            type: "success",
            duration: 5000,
            position: "top-right",
          });
        })
        .catch(() => {
          console.log("err");
          this.$emit("spinerClick", false);
          this.$toast.open({
            message: "No se ha podido editar la categoría, intenta nuevamente",
            type: "error",
            duration: 5000,
            position: "top-right",
          });
        });
    },
    async deleteCategorie() {
      this.$emit("spinerClick", true);
      let query = {
        idEmpresa: this.id_empresa,
        idUsuario: this.id_usuario,
        idCategoria: this.cat_to_delete.id_categoria,
      };
      await this.$apollo
        .mutate({
          mutation: VIDEO_CAT_DELETE,
          variables: query,
        })
        .then((res) => {
          let delete_cat = res.data.deleteCategoriaVideo.id_categoria;
          if (delete_cat !== "0") {
            this.addVideoCatAction({ action: "delete", data: delete_cat });
            this.$emit("spinerClick", false);
            this.open_question_modal = false;

            this.$toast.open({
              message: "Categoría eliminada con éxito.",
              type: "success",
              duration: 5000,
              position: "top-right",
            });
          }
          if (delete_cat == "0") {
            this.$emit("spinerClick", false);
            this.open_question_modal = false;
            this.$toast.open({
              message:
                "La categoría no ha podido ser eliminada, debido a que tiene videos asignados.",
              type: "error",
              duration: 5000,
              position: "top-right",
            });
          }
        })
        .catch(() => {
          this.$emit("spinerClick", false);
          this.$toast.open({
            message:
              "No se ha podido eliminar la categoría, intenta nuevamente.",
            type: "error",
            duration: 5000,
            position: "top-right",
          });
        });
    },
  },
};
</script>